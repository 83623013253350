<template>
  <div>
    <div v-if="displayLoading">
      <Loading />
    </div>

    <div class="page">
      <slot name="prepend"></slot>

      <div class="page__title">上傳發票照片</div>

      <div class="form">
        <div class="field">
          <label class="field__label" :class="'field--required'">上傳照片</label>
          <input ref="input" type="file" accept="image/*" @change="loadImage($event.target.files[0])" />
          <div v-if="v$.image.$error" class="form__error-msg">
            請上傳發票照片
          </div>
        </div>

        <div class="field">
          <label class="field__label" :class="'field--required'">發票號碼</label>

          <div class="row align-items-center">
            <div class="col-3 align-self-start">
              <input class="field__input" v-model="alphabetic_letter" maxlength="2"
                :class="{ invalid: v$.alphabetic_letter.$error }" readonly />
              <div v-if="v$.alphabetic_letter.$error" class="form__error-msg">
                請輸入正確發票字軌
              </div>
            </div>
            <span class="col-1 text-center align-self-start">-</span>
            <div class="col-8 align-self-start">
              <input class="field__input" v-model="number" maxlength="8" :class="{ invalid: v$.number.$error }"
                readonly />
              <div v-if="v$.number.$error" class="form__error-msg">
                請輸入正確發票號碼
              </div>
            </div>
          </div>
          <small class="field__desc">須包含英文，例：SH-87654321</small>
        </div>
        <div class="field">
          <label class="field__label" :class="'field--required'">開立時間</label>

          <datepicker v-model="invoice_at" bootstrap-styling format="yyyy/MM/dd" :language="zh" class="field__input py-0"
            :class="{ invalid: v$.invoice_at.$error }" disabled>
          </datepicker>
          <div v-if="v$.invoice_at.$error" class="form__error-msg">
            請輸入發票開立時間
          </div>
        </div>
        <div class="field">
          <label class="field__label" :class="'field--required'">隨機碼</label>
          <input class="field__input" v-model="random_number" maxlength="4" :class="{ invalid: v$.random_number.$error }"
            readonly />
          <div v-if="v$.random_number.$error" class="form__error-msg">
            請輸入發票隨機碼
          </div>
        </div>
      </div>
    </div>
    <div class="page__button">
      <SharedButton class="s-btn-bg-primary" @click="submit">送出</SharedButton>
    </div>

    <ImageResizer ref="imageResizer"></ImageResizer>
  </div>
</template>

<script>
import invoiceApi from '@/apis/liff/v2/invoice'
import useVuelidate from "@vuelidate/core";
import { required, alpha, integer } from "@vuelidate/validators";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import Datepicker from "vuejs-datepicker";
import { zh } from "vuejs-datepicker/dist/locale";
import { format } from "date-fns";
import Loading from "@/components/Page/Liff/Shared/Loading";
import ImageResizer from "@/components/Page/Liff/Einvoice/ImageResizer"

export default {
  components: { Datepicker, SharedButton, Loading, ImageResizer },
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),
  data() {
    return {
      zh,
      alphabetic_letter: null,
      image: null,
      number: null,
      invoice_at: null,
      random_number: null,
      displayLoading: false,
    };
  },
  validations() {
    return {
      alphabetic_letter: { required, alpha },
      number: { required, integer },
      invoice_at: { required },
      random_number: { required, integer },
      image: { required },
    };
  },

  methods: {
    loadImage(image) {
      this.detectQRCode(image)
      this.setupImageResizer(image)
    },
    async detectQRCode(image) {
      this.displayLoading = true;
      try {
        this.image = image
        const { data } = await invoiceApi.detectQRCode(image)
        if (data.invoice_number.includes('-')) {
          this.alphabetic_letter = data.invoice_number.split('-')[0]
          this.number = data.invoice_number.split('-')[1]
        } else {
          this.alphabetic_letter = data.invoice_number.substring(0, 2)
          this.number = data.invoice_number.substring(2)
        }
        this.invoice_at = data.timestamp
        this.random_number = data.random_code
      } catch (e) {
        this.$swal.fire({ title: "識別失敗", type: "error" })
      }
      this.displayLoading = false;
    },
    async setupImageResizer(image) {
      await this.$nextTick();

      const reader = new FileReader();

      reader.onload = (event) => {
        this.image = event.target.result;
        this.$refs.imageResizer.cropper.replace(this.image);
      };

      reader.readAsDataURL(image);
    },
    async submit() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }

      this.displayLoading = true;

      let params = {
        inv_num: `${this.alphabetic_letter}${this.number}`,
        inv_date: format(new Date(this.invoice_at), "yyyy/MM/dd"),
        random: this.random_number,
        type: 'electronic'
      };

      try {
        const image = await this.$refs.imageResizer.uploadImage();
        params.image_id = image.id;
        const { data } = await invoiceApi.updateOrCreateInvoiceLog(params);

        data.already_exists
          ? this.$swal.fire({ title: "發票已登入過", type: "warning" })
          : this.$swal.fire({ title: "儲存成功", type: "success" });

        this.reset()
      } catch (e) {
        console.log(e)
        this.$swal.fire({ title: "儲存失敗", type: "error" })
      }

      this.displayLoading = false;
    },
    reset() {
      this.v$.$reset()
      this.alphabetic_letter = null
      this.number = null
      this.invoice_at = null
      this.image = null
      this.random_number = null
      this.$refs.input.value = null
    }
  },
};
</script>

<style lang="scss" scoped>
.form {
  padding: 20px 10px;

  &__error-msg {
    color: #fe0000;
    font-size: 12px;
    margin-top: 8px;
  }
}

.field {
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }

  &__label {
    font-weight: 400;
    font-size: 14px;
    color: var(--liff-secondary_text_color);
    margin-bottom: 8px;
    display: block;
  }

  &__desc {
    color: var(--liff-secondary_text_color);
    font-size: 12px;
    line-height: 1;
    margin-top: 4px;
  }

  &__input {
    font-weight: 500;
    font-size: 16px;
    color: var(--liff-primary_text_color);
    border: none;
    border-bottom: 1px solid #e5e5ea;
    background: transparent;
    padding-bottom: 8px;
    width: 100%;
    border: 1px solid #e5e5ea;
    border-radius: 5px;
    padding: 8px 12px;

    &::placeholder {
      color: #b7b7b7;
    }

    &:focus {
      border: 1px solid var(--liff-primary_text_color);
    }

    &.invalid {
      border: 1px solid #fe0000;
    }

    &:read-only {
      background-color: #e9ecef;
    }
  }

  &--required {
    &:after {
      content: "*";
      position: relative;
      font-family: "PingFang TC";
      color: var(--s-primary);
      margin-left: 2px;
    }
  }

  &__select-group {
    display: flex;

    > * {
      width: 100%;

      &:not(:last-of-type) {
        margin-right: 8px;
      }
    }

    &.invalid {
      border-bottom: 1px solid #fe0000;
    }
  }
}
</style>
